import cookieparser from 'cookieparser'
// import jsCookie from 'js-cookie'

// import 'firebase/auth'
// getRedirectResult
import {
  getAuth, fetchSignInMethodsForEmail
  , GoogleAuthProvider, FacebookAuthProvider, createUserWithEmailAndPassword,
  signInWithRedirect, signInWithEmailAndPassword
  , signOut
  , getRedirectResult
  // , OAuthProvider
  // , linkWithCredential
  // , signInWithCredential
} from 'firebase/auth'

// import { getSecretValue } from '~/plugins/secret-manager'

export const strict = false
export const state = () => {
  return {
    errorMessage: null,
    domain: null,
    alert: { show: false, message: '', header: '' },
    alertWithLine: { show: false, message: '', header: '' },
    token: null,
    locales: ['en', 'th'],
    locale: 'th',
    layout: 'default',
    product: '',
    loading: false,
    loadingprice: false,
    showModalRequireLogin: false,
    authenticating: false,
    lastLoginFail: false,
    lastLoginChanel: '',
    userData: {
      id: null,
      photourl: null,
      name: null,
      email: null,
      uid: null,
      new_alert_count: 0,
      new_alert_order_count: 0,
      new_alert_design_count: 0,
      phone: null
    },
    user: {
      id: null
    },
    aws_s3: 'https://files.locopack.co/',
    s3: process.env === 'production' ? 'https://locopack-sandbox.locopack.co/' : 'https://locopack-sandbox.locopack.co/',
    order: '',
    klaroManager: null,
    semiDesignData: {},
    secrets: {},
    purposes: [],
    checkoutStandardProduct: {}
  }
}
export const mutations = {
  setPurposes (state, purposes) {
    state.purposes = purposes
  },
  setErrorMessage (state, message) {
    state.errorMessage = message
  },
  setState (state, values) {
    for (const key in values) {
      state[key] = values[key]
    }
  },
  setLang (state, locale) {
    // console.log(':: setLang ::', locale, state)
    if (state.locales.includes(locale)) {
      state.locale = locale
      this.dispatch('setCookie', { cookieName: 'locale', cookieValue: locale })
    }
  },
  setShowModalRequireLogin (state) {
    state.showModalRequireLogin = true
  },
  setProduct (state, product) {
    state.product = product
  },
  showLoadingCheckPrice (state) {
    state.loadingprice = true
  },
  hideLoadingCheckPrice (state) {
    state.loadingprice = false
  },
  showLoading (state) {
    state.loading = true
  },
  hideLoading (state) {
    state.loading = false
  },
  showAuthenticating (state) {
    // console.log('showAuthenticating', state)
    state.authenticating = true
  },
  hideAuthenticating (state) {
    // console.log('hideAuthenticating', state)
    state.authenticating = false
  },
  setToken (state, token, expireDay) {
    // console.log('>>>::1 setToken', token)
    // console.log('<<<--- index setToken --->>>', token, expireDay)
    state.token = token
    // console.log(':: setToken ::', state, token)
    this.dispatch('setCookie', { cookieName: 'token_' + process.env.NODE_ENV, cookieValue: token })
    // jsCookie.set('token_' + process.env.NODE_ENV, token, {
    //   domain: process.env.COOKIE_DOMAIN,
    //   path: '/'
    // })
  },
  clearToken (state) {
    state.token = null
    this.dispatch('removeCookie', { cookieName: 'token_' + process.env.NODE_ENV })
    this.dispatch('removeCookie', { cookieName: 'token' })
    // jsCookie.remove('token_' + process.env.NODE_ENV)
    // jsCookie.remove('token')
  },
  setLastLoginFail (state, status) {
    state.lastLoginFail = status
  },
  setLastLoginChannel (state, channel) {
    state.lastLoginChanel = channel
  },
  setNewAlertCount (state, data) {
    state.userData.new_alert_order_count = data.new_alert_order_count ? data.new_alert_order_count : 0
    state.userData.new_alert_design_count = data.new_alert_design_count ? data.new_alert_design_count : 0
    state.userData.new_alert_count = data.new_alert_count ? data.new_alert_count : 0
  },
  setUserData (state, data, expireDay) {
    console.log('>>>> setUserData <<<<', data)
    let photourl = ''
    if (data && typeof data === 'string') {
      data = JSON.parse(data)
    }
    if (typeof data.photourl !== 'undefined' && data.photourl !== '') {
      photourl = data.photourl
    } else if (state.userData && typeof state.userData.photourl !== 'undefined' && state.userData.photourl) {
      photourl = state.userData.photourl
    }
    // console.log('setUserData data', data, 'type', typeof data)
    // console.log('setUserData user', state.userData, 'type', typeof state.userData)

    let uid = ''
    // if (data && 'uid' in data) {
    if (data && 'uid' in data) {
      uid = data.uid
    } else if (state.userData && 'uid' in state.userData) {
      uid = state.userData.uid
    }

    state.userData = {
      id: data.id,
      photourl,
      name: data.name || state.userData.name,
      email: data.email || state.userData.email,
      new_alert_order_count: data.new_alert_order_count ? data.new_alert_order_count : 0,
      new_alert_design_count: data.new_alert_design_count ? data.new_alert_design_count : 0,
      new_alert_count: data.new_alert_count ? data.new_alert_count : 0,
      uid,
      phone: data.phone || state.userData.phone
    }
    // console.log('1) expireDay', expireDay)
    if (typeof expireDay === 'undefined') {
      this.dispatch('setCookie', { cookieName: 'userData', cookieValue: state.userData })
      // jsCookie.set('userData', state.userData, {
      //   domain: process.env.COOKIE_DOMAIN,
      //   path: '/'
      // })
    }
  },
  storeOrder (state, order) {
    state.order = order
  },
  setAlert (state, alert) {
    state.alert = {
      show: alert.show ?? false,
      message: alert.message ?? '',
      header: alert.header ?? 'Alert'
    }
  },
  setAlertWithLine (state, alertWithLine) {
    state.alertWithLine = {
      show: alertWithLine.show ?? false,
      message: alertWithLine.message ?? '',
      header: alertWithLine.header ?? 'แจ้งเดือน'
    }
  },
  setUser (state, user) {
    state.user = user
  },
  setSecrets (state, secrets) {
    state.secrets = secrets
  },
  setCheckoutStandardProduct (state, checkoutStandardProduct) {
    state.checkoutStandardProduct = checkoutStandardProduct
  },
  SET_KLARO_MANAGER (state, klaroManager) {
    state.klaroManager = klaroManager
  },
  setSemiDesignData (state, semiDesignData) {
    state.semiDesignData = semiDesignData
  },
  isNumber (evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault()
    } else {
      return true
    }
  },
  async getActivePurposes (state) {
    if (state.userData.phone === null) {
      const result = await this.$axios.get('me')
      if (result.data.user) {
        state.userData.phone = result.data.user.phone
      }
    }

    const params = {
      params: {
        phone: state.userData.phone
        // phone: state.userData.phone + '2'
      }
    }
    try {
      const result = await this.$axios.$get('scgppdpa-get-active-purposes', params)
      if (result) {
        this.commit('setPurposes', result.data.purposes)
      }
    } catch (errors) {
      console.log('error', errors.message)
    }
  }
}
export const getters = {
  isAuthenticated (state) {
    // console.log(':: debug >> isAuthenticated state ::', state.token)
    return state.token !== null && typeof state.token !== 'undefined'
  },
  isAuthenticating (state) {
    return state.authenticating
  },
  getUser (state) {
    return state.user
  },
  getToken (state) {
    return state.token
  },
  isKlaroConfirmed (state) {
    return state.klaroManager.confirmed
  },
  semiDesignData (state) {
    return state.semiDesignData
  },
  checkoutStandardProductData (state) {
    return state.checkoutStandardProduct
  },
  getLastLoginFail (state) {
    return state.lastLoginFail
  },
  getLastLoginChannel (state) {
    return state.lastLoginFail
  },
  getPurposes (state) {
    return state.purposes
  }
}
export const actions = {
  async nuxtServerInit ({ commit, dispatch, state }, { req, app }) {
    if (req) {
      if (typeof req.headers.cookie !== 'undefined') {
        // console.log(':: req ::', req.headers.cookie, app.$cookies.getAll())
      }
      const host = req.headers.host
      if (host.startsWith('www.')) {
        state.domain = '.locopack.co'
      } else {
        const parts = host.split(':')
        const hostname = parts[0]
        state.domain = '.' + hostname
      }
      // } else if (host === 'staging.locopack.co') {
      //   const parts = host.split(':')
      //   const hostname = parts[0]
      //   state.domain = '.' + hostname
      // }
    }
    try {
      let parsed = null
      if (req.headers.cookie !== undefined && req.headers.cookie !== null) {
        // console.log(':: cookie length ::', req.headers.cookie.length, req.headers.cookie)
        // if (req.headers.cookie && req.headers.cookie.length > 4096) {
        //   const cookie = require('cookie')
        //   parsed = cookie.parse(req.headers.cookie)
        //   for (const k in parsed) {
        //     if (k.indexOf('order') === 0) {
        //       delete parsed[k]
        //       app.$cookies.remove(k)
        //     }
        //   }
        // } else {
        //   parsed = cookieparser.parse(req.headers.cookie)
        // }

        // console.log('--- req.headers.cookie ---', req.headers.cookie)
        console.log('--- cookie length ---', req.headers.cookie.length)
        if (req.headers.cookie && req.headers.cookie.length > 4096) {
          console.log('[/] cookie more than 4096')
          //   console.log('[-] $cookies', app.$cookies)
          app.$cookies.removeAll({ path: '/' })
          const cookies = app.$cookies.getAll()
          //   app.$cookies.remove()

          for (const k in cookies) {
            if (process.env.NODE_ENV === 'staging') {
              app.$cookies.remove(k, { path: '/', domain: '.locopack.co' })
            }
            app.$cookies.remove(k, { path: '/' })
          // app.$cookies.remove()
          }
        } else {
          console.log('[x] cookie more than 4096')
          parsed = await cookieparser.parse(req.headers.cookie)
        }

        console.log('::: parse cookie :::', parsed)
        console.log('::: all cookie :::', await this.$cookies.getAll())
        if (parsed !== null && typeof parsed['token_' + process.env.NODE_ENV] !== 'undefined' && (parsed['token_' + process.env.NODE_ENV] !== '' || parsed['token_' + process.env.NODE_ENV] !== null)) {
          // console.log('>>> beforeValidAuth <<<')
          const validAuth = await dispatch('validAuth', { token: parsed['token_' + process.env.NODE_ENV] })
          console.log('[-] validAuth :: ', validAuth)
          if (validAuth === false) {
            console.log('x nuxtServerInit :: validAuth:false x', validAuth, 'token', 'token_' + process.env.NODE_ENV, parsed['token_' + process.env.NODE_ENV])
            parsed['token_' + process.env.NODE_ENV] = null
            parsed.userData = state.userData
            // console.log('*** removeAllCookie +++')
            await dispatch('removeFrontendCookieAll', { req, app })
          }

          console.log('[-] parsed token :: ', parsed['token_' + process.env.NODE_ENV])
          if (typeof parsed['token_' + process.env.NODE_ENV] !== 'undefined') {
            // console.log(':: nuxtServerInit :: has token ', validAuth, 'token', 'token_' + process.env.NODE_ENV, parsed['token_' + process.env.NODE_ENV])
            // console.log('--- ### set ### ---', parsed['token_' + process.env.NODE_ENV])
            // console.log('--- ### parsed ### ---', parsed)
            commit('setToken', parsed['token_' + process.env.NODE_ENV])
          }
          const userData = parsed.userData
          // console.log(':: userData :: =>> ', userData)
          if (userData !== undefined) {
            commit('setUserData', userData)
          }
          const locale = parsed.locale
          commit('setLang', locale !== undefined ? locale : null)
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error NuxtServerInit index.js :: ', e.message)
    }

    // try {
    //   const secrets = await getSecretValue('locopack-backend-staging')
    //   console.log('secretsssss', secrets)
    //   // Use secrets here or commit to the Vuex store
    //   commit('setSecrets', secrets)
    // } catch (err) {
    //   console.error('!!!! Failed to fetch secrets:', err)
    // }
  },
  setError ({ commit }, errorMessage) {
    localStorage.setItem('errorMessage', errorMessage)
    commit('setErrorMessage', errorMessage)
  },
  setCookie ({ commit, state }, { cookieName, cookieValue, options }) {
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 15)
    const optionsData = {
      expires: expirationDate,
      path: '/'
    }

    // console.log(':: debug state ::', state)
    if (state.domain) {
      optionsData.domain = state.domain
    }

    // console.log(':: debug setCookie ::', optionsData, cookieName, cookieValue)
    this.$cookies.set(cookieName, cookieValue, optionsData)
  },
  getCookie ({ state, commit }, { cookieName }) {
    const options = { path: '/' }
    if (state.domain) {
      options.domain = state.domain
    }
    const cookieFromRoot = this.$cookies.get(cookieName, options)
    // console.log(':: getCookie ::', options, cookieFromRoot)
    return cookieFromRoot
  },
  // setCookie ({ commit, dispatch, state, rootState }, { cookieName, cookieValue }) {
  //   console.log('setCookie', cookieName, cookieValue, 'jsCookies')
  //   // console.log('jsCookies in Vuex action:', this.$jsCookies)
  //   const expirationDate = new Date()
  //   expirationDate.setDate(expirationDate.getDate() + 30)
  //   this.$jsCookies.set(cookieName, cookieValue, {
  //     expires: expirationDate
  //   })
  // },
  // getCookie ({ commit, dispatch, state }, { cookieName }) {
  //   // console.log('getCookie', cookieName)
  //   this.$jsCookies.get(cookieName)
  // },
  removeCookie ({ commit, dispatch, state }, { cookieName }) {
    this.$cookies.remove(cookieName, { path: '/' })
    // console.log('::: removeCookie :::', cookieName, this.$cookies.get(cookieName))
  },
  // cookiesStringToJson (vuexContext, { req, app }, cookiesString) {
  //   const cookiesArray = cookiesString.split(' ')
  //   const cookiesObject = {}

  //   cookiesArray.forEach((cookie) => {
  //     const [name, value] = cookie.split('=')
  //     cookiesObject[name] = value
  //   })
  //   return JSON.stringify(cookiesObject, null, 2) // The last argument (2) is for indentation
  // },
  async removeAllCookies () {
    console.log('------- removeAllCookiesSSS ------')
    // รับคุกกี้ทั้งหมด
    const cookies = await this.$cookies.getAll()

    // วนลูปผ่านคุกกี้ทั้งหมดและลบแต่ละคุกกี้
    for (const cookie in cookies) {
      console.log('[rm] cookie ', cookie)
      await this.$cookies.remove(cookie)
    }
    await this.$cookies.removeAll()
    const cookiesAll = await this.$cookies.getAll()
    console.log('Alllllllll', cookiesAll)
  },
  removeAllCookie ({ commit, dispatch, state }, { req, app }) {
    let parsed = null
    const cookie = require('cookie')
    // parsed = dispatch('cookiesStringToJson', req.headers.cookie)
    parsed = cookie.parse(req.headers.cookie)
    // console.log('>>>>>> nuxtServerInit 2 <<<<<< parsed', parsed)

    for (const k in parsed) {
      if (k.indexOf('userData') === 0 || k.indexOf('token_' + process.env.NODE_ENV) === 0 || k.indexOf('expirationDate') === 0) {
        if (k === 'userData') {
          parsed[k] = {
            photourl: null,
            name: null,
            email: null,
            uid: null,
            new_alert_count: 0,
            new_alert_order_count: 0,
            new_alert_design_count: 0
          }
        } else {
          parsed[k] = null
        }
        const expirationDate = new Date()
        expirationDate.setDate(expirationDate.getDate() + 15)
        app.$cookies.set(k, parsed[k], {
          expires: expirationDate,
          // domain: process.env.COOKIE_DOMAIN,
          path: '/'
        })
        // delete parsed[k]
        // app.$cookies.remove(k)
      } else {
        app.$cookies.remove(k)
      }
    }
    // console.log('--- removeAllCookie LAST ---', parsed)
    return parsed
  },
  async removeFrontendCookieAll ({ dispatch }) {
    console.log(':x: removeFrontendCookieAll :x:')
    try {
      // dispatch('removeAllCookies')
      await this.$cookies.removeAll()
      console.log('After removeAll - Cookies:', await this.$cookies.getAll())
    } catch (error) {
      console.error('Error removing cookies:', error)
    }
    // const allCookies = this.$cookies.getAll()

    // const arrayCookieName = ['test', 'token_' + process.env.NODE_ENV, 'expirationDate', 'locale', 'userData', 'i18n_redirected']
    // await Object.keys(allCookies)
    //   .filter(cookieName => arrayCookieName.includes(cookieName))
    //   .forEach(async (cookieName) => {
    //     const cookie = await allCookies[cookieName]
    //     await this.$cookies.remove(cookieName, { path: cookie.path })
    //   })

    // console.log(':: all cookies ::', this.$cookies.getAll())
    // this.$cookies.remove('token_' + process.env.NODE_ENV)
    // this.$cookies.remove('expirationDate')
    // this.$cookies.remove('locale')
    // this.$cookies.remove('userData')
    // this.$cookies.remove('i18n_redirected')
  },
  setUser (vuexContext, user) {
    vuexContext.commit('setUser', user)
  },
  async validAuth ({ commit, dispatch }, authData) {
    // console.log('##--- validAUTH ---,,,,', authData)
    // const { data } = await this.$axios.get('/user/auth', {
    //   headers: {
    //     Authorization: 'Bearer ' + authData.token
    //   }
    // })
    // console.log('validAuth success', data)
    try {
      // const { data } = await this.$axios.get('/oauth/tokens', {
      // console.log('baseee', this.$axios.defaults.baseURL)
      const { data } = await this.$axios.get('/user/auth', {
        headers: {
          Authorization: 'Bearer ' + authData.token
        }
      })
      // console.log('validAuth success', data)
      return data
    } catch (e) {
      console.log('validAuth wrong', 'token => ', authData.token, e.message)
      if (e.response.status === 401) {
        return false
      }
      // console.log('ERROR ', e.message, 'stattt', e.response.status)
    }
  },
  async locoAuth (vuexContext, authData) {
    // console.log('authhhh Dataaaa >>> ', authData)
    const { data } = await this.$axios.post('/auth', {
      idToken: authData.token,
      rememberme: authData.rememberme || false,
      name: authData.name || null,
      phone: authData.phone || null
    })
    // console.log('locoAuth response', data)
    if (!data.error) {
      if (authData.rememberme) {
        vuexContext.commit('setToken', data.token, 30)
        vuexContext.commit('setUserData', data, 30)
      } else {
        vuexContext.commit('setToken', data.token, 30)
        vuexContext.commit('setUserData', data, 30)
      }

      // const dateExpired = new Date().getTime() + (data.expires_in) * 1000
      // console.log('EXP: ' + dateExpired.toLocaleString())
      this.dispatch('setCookie', { cookieName: 'expirationDate', cookieValue: new Date().getTime() + (data.expires_in) * 1000 })
      // jsCookie.set('expirationDate', new Date().getTime() + (data.expires_in) * 1000, {
      //   expires: 30,
      //   domain: process.env.COOKIE_DOMAIN,
      //   path: '/'
      // })
      if ('social' in authData && authData.social === false) {
        setTimeout(() => {
          vuexContext.commit('hideLoading')
        }, 5000)
      }
      if (process.client && localStorage.getItem('start-login') === '1') {
        localStorage.removeItem('start-login')
        if (localStorage.getItem('redirect_url')) {
          const redirectUrl = localStorage.getItem('redirect_url')
          localStorage.removeItem('redirect_url')

          this.$router.push(redirectUrl)
        } else if (localStorage.getItem('click-login-from-page')) {
          let strFromPage = localStorage.getItem('click-login-from-page')
          if (strFromPage.match('___') !== null) {
            const tmpStr = strFromPage.split('___')
            strFromPage = tmpStr[0]
          }
          const routeOpt = { name: strFromPage }
          const paramsJson = JSON.parse(localStorage.getItem('click-login-from-page-params'))
          if (localStorage.getItem('click-login-from-page-params') !== '') {
            routeOpt.params = paramsJson
          }
          const paramsQuery = JSON.parse(localStorage.getItem('click-login-from-page-query'))
          if (localStorage.getItem('click-login-from-page-query') !== '') {
            routeOpt.query = paramsQuery
          }
          // console.log('locoAuth return 200 1', authData.phone)
          if (typeof authData.phone !== 'undefined' && authData.phone !== '') { // register by email
            return { status: 200 }
          } else {
            // console.log(routeOpt)

            this.$router.push(this.localeLocation(routeOpt))
          }
        } else {
          this.$router.push(this.localeLocation('/'))
          // return { status: 200 }
        }
      }
      vuexContext.commit('hideLoading')
      return { status: 200 }
    } else {
      vuexContext.commit('hideAuthenticating')
      vuexContext.commit('setAlert', { show: true, message: 'กรุณาติดต่อเราที่ line: @locopack', header: 'พบปัญหาในการ Login' })
      return { status: 0, message: data.error_reason }
    }
  },
  async callbackAuthen (vuexContext) {
    vuexContext.commit('showAuthenticating')
    const auth = getAuth()
    // const prevUser = auth.currentUser

    // console.log('creDDENTIALssss', auth.credential)
    // auth.currentUser.link(credential).then(function(user) {
    //   console.log("Anonymous account successfully upgraded", user)
    // }, function(error) {
    //   console.log("Error upgrading anonymous account", error)
    // })

    if (typeof getRedirectResult !== 'undefined') {
      try {
        const result = await getRedirectResult(auth)
        if (result) {
          const user = result.user
          if (user && user.accessToken !== '') {
            await vuexContext.dispatch('locoAuth', { token: user.accessToken, rememberme: true, social: true })
          }
        }
        vuexContext.commit('hideAuthenticating')
      } catch (error) {
        vuexContext.commit('hideAuthenticating')
        // vuexContext.commit('hideLoading')
        const startLoginWith = localStorage.getItem('start-login-with')
        const errorCode = error.code
        let errorMessage = error.message
        // const email = error.email
        // const credentialGG = GoogleAuthProvider.credentialFromError(error)
        // const credentialFB = FacebookAuthProvider.credentialFromError(error)
        // console.log(errorCode, errorMessage, error.email, credentialGG, credentialFB)
        if (errorCode === 'auth/account-exists-with-different-credential') {
          errorMessage = 'บัญชีของท่านได้มีการลงทะเบียนด้วยวิธีอื่นแล้ว <br/>'
          if (startLoginWith === 'facebook') {
            errorMessage = errorMessage + 'ท่านอาจใช้ <b>Google</b> หรือ <b>Email</b> หรือ <b>เบอร์โทร</b>'
          } else if (startLoginWith === 'email') {
            errorMessage = errorMessage + 'ท่านอาจใช้ <b>Facebook</b> หรือ <b>Google</b> หรือ <b>เบอร์โทร</b>'
          } else if (startLoginWith === 'google') {
            errorMessage = errorMessage + 'ท่านอาจใช้ <b>Facebook</b> หรือ <b>Email</b> หรือ <b>เบอร์โทร</b>'
          }
          vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'พบปัญหาในการเข้าใช้งาน ด้วย ' + startLoginWith })
          vuexContext.commit('setLastLoginFail', true)
          vuexContext.commit('setLastLoginChannel', startLoginWith)
          localStorage.removeItem('start-login-with')
        } else {
          errorMessage = 'กรุณาติดต่อเราที่ line @locopack ' + errorCode + ' ' + errorMessage
          vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'พบปัญหาในการเข้าใช้งาน ด้วย ' + startLoginWith })
        }
      }
    }
  },
  // async callbackAuthen (vuexContext) {
  //   vuexContext.commit('showAuthenticating')
  //   const auth = await getAuth()

  //   // console.log('aaaaa', auth, credential)
  //   // const methods = await fetchSignInMethodsForEmail(auth, email)
  //   // const oldCred = await auth.credential(methods[0])
  //   // const prevUser = await auth.signInWithCredential(oldCred)
  //   // console.log(',,,,,,,,', methods, oldCred, prevUser)

  //   // TTTESTST

  //   // getRedirectResult(auth).then(async function (result) {
  //   //   console.log('login successs', result)
  //   //   const user = result.user
  //   //   if (user && user.accessToken !== '') {
  //   //     await vuexContext.dispatch('locoAuth', { token: user.accessToken, rememberme: true, social: true })
  //   //   }
  //   // }, function (error) {
  //   //   vuexContext.commit('hideAuthenticating')
  //   //   const startLoginWith = localStorage.getItem('start-login-with')
  //   //   const errorCode = error.code
  //   //   let errorMessage = error.message
  //   //   if (errorCode === 'auth/account-exists-with-different-credential') {
  //   //     errorMessage = 'บัญชีของท่านได้มีการลงทะเบียนด้วยวิธีอื่นแล้ว <br/>'
  //   //     if (startLoginWith === 'facebook') {
  //   //       errorMessage = errorMessage + 'ท่านอาจใช้ <b>Google</b> หรือ <b>Email</b> หรือ <b>เบอร์โทร</b>'
  //   //     } else if (startLoginWith === 'email') {
  //   //       errorMessage = errorMessage + 'ท่านอาจใช้ <b>Facebook</b> หรือ <b>Google</b> หรือ <b>เบอร์โทร</b>'
  //   //     } else if (startLoginWith === 'google') {
  //   //       errorMessage = errorMessage + 'ท่านอาจใช้ <b>Facebook</b> หรือ <b>Email</b> หรือ <b>เบอร์โทร</b>'
  //   //     }
  //   //     vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'พบปัญหาในการเข้าใช้งาน ด้วย ' + startLoginWith })
  //   //     vuexContext.commit('setLastLoginFail', true)
  //   //     vuexContext.commit('setLastLoginChannel', startLoginWith)
  //   //     localStorage.removeItem('start-login-with')
  //   //   } else {
  //   //     errorMessage = 'กรุณาติดต่อเราที่ line @locopack ' + errorCode + ' ' + errorMessage
  //   //     vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'พบปัญหาในการเข้าใช้งาน ด้วย ' + startLoginWith })
  //   //   }
  //   //   // const credential = await FacebookAuthProvider.credentialFromError(error)
  //   //   // The email of the user's account used.
  //   //   // const email = error.customData.email
  //   //   // const oldCred = await FacebookAuthProvider.credential(credential)
  //   //   // const prevUser = await auth().signInWithCredential(oldCred)
  //   //   // console.log('mmmmmmmm><><><', methods, oldCred, prevUser)
  //   //   // The AuthCredential type that was used.
  //   //   // const credential = FacebookAuthProvider.credentialFromError(error)
  //   //   // const credential2 = GoogleAuthProvider.credentialFromError(error)

  //   //   // console.log('>>>>|||<<<<', email, credential)
  //   //   // const email = error.email
  //   //   // const credential = error.credential
  //   //   // if (error.code === 'auth/account-exists-with-different-credential') {
  //   //   //   fetchSignInMethodsForEmail(auth, email).then(async function (providers) {
  //   //   //     console.log('providers ===>>> ', credential, providers)
  //   //   //     // const credential = FacebookAuthProvider.credentialFromError(error)
  //   //   //     const auth = await getAuth()
  //   //   //     const methods = await fetchSignInMethodsForEmail(auth, email)
  //   //   //     const provider = await getProviderForProviderId(methods[0])
  //   //   //     auth.signInWithPopup(provider).then(result => {
  //   //   //       console.log('rrrrrrrrr', result)
  //   //   //       // result.user.linkWithCredential(pendingCred).then(usercred => {
  //   //   //       //   goToApp()
  //   //   //       // })
  //   //   //     })
  //   //   //     const res = await linkWithCredential(auth, credential)
  //   //   //       .then(function (usercred) {
  //   //   //         console.log('I', usercred)
  //   //   //         return { error: false }
  //   //   //       }).catch(function (error) {
  //   //   //         console.log('II', error)
  //   //   //         return { error: true, message: error.message }
  //   //   //       })
  //   //   //     if (!res.error) {
  //   //   //       console.log('succes', res)
  //   //   //     } else {
  //   //   //       console.log('failed', res)
  //   //   //     }
  //   //   //     console.log('ememmem', email, credential)
  //   //   //   })
  //   //   // }
  //   // })
  //   // vuexContext.commit('hideAuthenticating')
  //   console.log('getResulttt', getRedirectResult)
  //   if (typeof getRedirectResult !== 'undefined') {
  //     try {
  //       const result = await getRedirectResult(auth)
  //       if (result) {
  //         const user = result.user
  //         console.log('RRRUSERRRR', result.user)
  //         if (user && user.accessToken !== '') {
  //           await vuexContext.dispatch('locoAuth', { token: user.accessToken, rememberme: true, social: true })
  //         }
  //       }
  //       vuexContext.commit('hideAuthenticating')
  //     } catch (error) {
  //       vuexContext.commit('hideAuthenticating')
  //       // vuexContext.commit('hideLoading')
  //       const startLoginWith = localStorage.getItem('start-login-with')
  //       const errorCode = error.code
  //       let errorMessage = error.message
  //       if (errorCode === 'auth/account-exists-with-different-credential') {
  //         errorMessage = 'บัญชีของท่านได้มีการลงทะเบียนด้วยวิธีอื่นแล้ว <br/>'
  //         if (startLoginWith === 'facebook') {
  //           errorMessage = errorMessage + 'ท่านอาจใช้ <b>Google</b> หรือ <b>Email</b> หรือ <b>เบอร์โทร</b>'
  //         } else if (startLoginWith === 'email') {
  //           errorMessage = errorMessage + 'ท่านอาจใช้ <b>Facebook</b> หรือ <b>Google</b> หรือ <b>เบอร์โทร</b>'
  //         } else if (startLoginWith === 'google') {
  //           errorMessage = errorMessage + 'ท่านอาจใช้ <b>Facebook</b> หรือ <b>Email</b> หรือ <b>เบอร์โทร</b>'
  //         }
  //         vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'พบปัญหาในการเข้าใช้งาน ด้วย ' + startLoginWith })
  //         vuexContext.commit('setLastLoginFail', true)
  //         vuexContext.commit('setLastLoginChannel', startLoginWith)
  //         localStorage.removeItem('start-login-with')
  //       } else {
  //         errorMessage = 'กรุณาติดต่อเราที่ line @locopack ' + errorCode + ' ' + errorMessage
  //         vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'พบปัญหาในการเข้าใช้งาน ด้วย ' + startLoginWith })
  //       }
  //     }
  //   }
  // },
  setLoadingState (vuexContext, show) {
    if (show) {
      vuexContext.dispatch('showLoading')
    } else {
      vuexContext.dispatch('hideLoading')
    }
  },
  authenSocial (vuexContext, authData) {
    const auth = getAuth()
    signInWithRedirect(auth, authData.provider)

    // After returning from the redirect when your app initializes you can obtain the result
    // const result = await getRedirectResult(auth)
    // console.log('>>> authhhhh <<<', signIn, result)
    // if (result) {
    //   // This is the signed-in user
    //   const user = result.user
    //   // This gives you a Facebook Access Token.
    //   const credential = authData.provider.credentialFromResult(auth, result)
    //   const token = credential.accessToken

    //   console.log(user, token)
    // }
    // return signIn
  },
  authenticateUserFacebook (vuexContext) {
    const auth = getAuth()

    return vuexContext.dispatch('authenSocial', { provider: new FacebookAuthProvider(auth) })
  },
  authenticateUserGoogle (vuexContext) {
    const auth = getAuth()
    return vuexContext.dispatch('authenSocial', { provider: new GoogleAuthProvider(auth) })
  },
  async authenticateSmsUser (vuexContext, authData) {
    console.log('-- authenticateSmsUser --', authData)
    console.log('-- authenticateSmsUser phonee--', authData.phone)

    const expDay = 7
    await this.dispatch('removeFrontendCookieAll')
    console.log('aurhhhh', authData)
    if (authData.token && authData.uid) {
      await vuexContext.commit('setToken', authData.token, expDay)
      await vuexContext.commit('setUserData', authData, expDay)
      await this.dispatch('setCookie', { cookieName: 'expirationDate', cookieValue: new Date().getTime() + (authData.expires_in) * 1000 })
      // jsCookie.set('expirationDate', new Date().getTime() + (authData.expires_in) * 1000, {
      //   expires: 30,
      //   domain: process.env.COOKIE_DOMAIN,
      //   path: '/'
      // })
      localStorage.removeItem('start-login')
      if (localStorage.getItem('redirect_url')) {
        const redirectUrl = localStorage.getItem('redirect_url')
        localStorage.removeItem('redirect_url')
        this.$router.push(redirectUrl)
      } else if (localStorage.getItem('click-login-from-page') !== '') {
        const routeOpt = { name: localStorage.getItem('click-login-from-page') }
        const paramsJson = JSON.parse(localStorage.getItem('click-login-from-page-params'))
        if (localStorage.getItem('click-login-from-page-params') !== '') {
          routeOpt.params = paramsJson
        }
        const paramsQuery = JSON.parse(localStorage.getItem('click-login-from-page-query'))
        if (localStorage.getItem('click-login-from-page-query') !== '') {
          routeOpt.query = paramsQuery
        }
        this.$router.push(this.localeLocation(routeOpt))
      } else {
        this.$router.push(this.localeLocation('/'))
      }
    } else {
      alert('sms login fail.')
    }
  },
  authenticateUser (vuexContext, authData) {
    const auth = getAuth()
    // vuexContext.commit('clearToken')
    // console.log('authenicateUser', authData.isLogin)
    if (!authData.isLogin) {
      return createUserWithEmailAndPassword(auth, authData.email, authData.password)
        .then((userCredential) => {
          const user = userCredential.user
          const result = vuexContext.dispatch('locoAuth', { token: user.accessToken, rememberme: authData.rememberme, name: authData.name, phone: authData.phone, social: false })
          return result
        })
        .catch(async (e) => {
          let errorMessage = e.code
          if (authData.email) {
            const provider = await fetchSignInMethodsForEmail(auth, authData.email)
            if (provider && provider[0]) {
              if (provider[0] === 'facebook.com') {
                errorMessage = 'ไม่สามารถเข้าสู่ระบบได้ พบการลงทะเบียนด้วย Facebook account ด้วย email  ' + authData.email
              } else if (provider[0] === 'google.com') {
                errorMessage = 'ไม่สามารถเข้าสู่ระบบได้ พบการลงทะเบียนด้วย Google Account ด้วย email  ' + authData.email
              }
            }
          }

          // vuexContext.commit('hideLoading')
          // vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'Login Fail' })
          return { status: e.code, message: errorMessage }
        })
    } else {
      return signInWithEmailAndPassword(auth, authData.email, authData.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user
          vuexContext.commit('hideAuthenticating')
          return vuexContext.dispatch('locoAuth', { token: user.accessToken, rememberme: authData.rememberme, social: false })
        })
        .catch(async (e) => {
          let errorMessage = e.code
          if (authData.email) {
            const provider = await fetchSignInMethodsForEmail(auth, authData.email)
            if (provider && provider[0]) {
              if (provider[0] === 'facebook.com') {
                errorMessage = 'ไม่สามารถเข้าสู่ระบบได้ พบการลงทะเบียนด้วย Facebook account ด้วย email  ' + authData.email
              } else if (provider[0] === 'google.com') {
                errorMessage = 'ไม่สามารถเข้าสู่ระบบได้ พบการลงทะเบียนด้วย Google Account ด้วย email  ' + authData.email
              }
            }
          }
          vuexContext.commit('hideLoading')
          vuexContext.commit('setAlert', { show: true, message: errorMessage, header: 'Login fail' })
        })
      // try {
      //   const result = await signInWithEmailAndPassword(auth, authData.email, authData.password)
      //   vuexContext.commit('hideAuthenticating')
      //   console.log('signInWithEmailAndPassword', result)
      //   let tokenId = ''
      //   if (result.user.xa !== false) {
      //     tokenId = result.user.xa
      //   } else {
      //     tokenId = result.user.ya
      //   }
      //   return vuexContext.dispatch('locoAuth', { token: tokenId, rememberme: authData.rememberme, social: false })
      // } catch (e) {
      //   vuexContext.commit('hideAuthenticating')
      //   let errorMessage = e.message
      //   console.log(errorMessage)
      //   if (authData.email) {
      //     const provider = await fetchSignInMethodsForEmail(auth, authData.email)
      //     if (provider && provider[0]) {
      //       if (provider[0] === 'facebook.com') {
      //         errorMessage = 'ไม่สามารถเข้าสู่ระบบได้ พบการลงทะเบียนด้วย Facebook account ด้วย email  ' + authData.email
      //       } else if (provider[0] === 'google.com') {
      //         errorMessage = 'ไม่สามารถเข้าสู่ระบบได้ พบการลงทะเบียนด้วย Google Account ด้วย email  ' + authData.email
      //       }
      //     }
      //   }
      //   return { status: e.code, message: errorMessage }
      // }
    }
  },
  initAuth (vuexContext, req) {
    const token = this.dispatch('getCookie', { cookieName: 'token_' + process.env.NODE_ENV })
    const expirationDate = this.dispatch('getCookie', { cookieName: 'expirationDate' })

    if ((expirationDate && new Date().getTime() > expirationDate) || !token) {
      vuexContext.dispatch('logout')
      return
    }
    vuexContext.commit('setToken', token)
  },
  async logout ({ state, commit, dispatch }) {
    // console.log('****** logout ******')
    const auth = getAuth()
    commit('showLoading')
    try {
      await this.$axios.post('/signout')
      await signOut(auth)
      await dispatch('removeFrontendCookieAll')
      state.token = null
      state.authenticating = false
      commit('hideLoading')
    } catch (e) {
      commit('hideLoading')
      console.log('error : ', e.message)
    }
    // console.log(':: isAuthenticated ::', state.isAuthenticated)
    // console.log(':: isAuthenticating ::', state.isAuthenticating)
  },
  async getAllCookies () {
    await this.$cookies.getAll()
    // const cookieValue = await this.$cookies.getAll()
    // console.log('cookie', cookieValue)
  },
  async clearCookieAll ({ commit, dispatch }) {
    await Object.keys(this.$cookies.getAll()).forEach((cookieName) => {
      this.$cookies.remove(cookieName)
    })

    await dispatch('logout')
  },
  async initializeApp ({ dispatch }, liffID) {
    // console.log('*** initApp:js ***')

    try {
      await window.liff.init({ liffId: liffID })

      // Get user profile
      const lineProfile = await window.liff.getDecodedIDToken()
      // console.log('* lineProfile *', lineProfile)
      // Check if the token is expired
      if (lineProfile !== null && dispatch('isTokenExpired', lineProfile.exp)) {
        // console.log('line token is expired')
        window.liff.logout()
      }

      // Check if the user is logged in
      if (!window.liff.isLoggedIn()) {
        // console.log('not logged in')
        window.liff.login()
      }

      // console.log('initialized successfully')
      // ... remaining code ...
    } catch (error) {
      console.log('Error initializing LIFF:', error.message)
    }
  },
  isTokenExpired (expirationTime) {
    // Get the current time in seconds
    const currentTime = Math.floor(Date.now() / 1000)

    // Compare the current time with the expiration time
    return currentTime >= expirationTime
  }
}
